import styled from 'styled-components';
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device';

export const InheritedRetinalDiseasesPatient = styled.div`
  .internal-banner {
    .internal-banner__contant {
      @media ${device.ipadLandscape} {
        top: 56px;
      }
      .o-container {
        @media ${device.mobileMedium} {
          padding: 0 2rem;
        }
        .internal-banner__large-title {
          @media ${device.desktopStart} {
            padding-left: 0;
          }
        }
      }
    }
  }
  .retinal-video-section {
    .o-container {
      .home-video-section {
        .video-section__inner-content {
          .video-section__inner-content__video-btn {
            .dtp-arrow-down {
              font-size: 9px;
              display: inline-block;
              transition: ease 0.3s;

              @media ${device.laptopMedium} {
                margin-left: 5px;
              }

              &:before {
                content: '';
                display: table;
                width: 21px;
                height: 11px;
                background-repeat: no-repeat;
                position: relative;
                left: 3px;
                top:3px;
              }

              &.rotate {
                position: relative;
                transform: rotate(180deg) !important;

              }
            }
          }
        }
      }
    }
  }

  .infographic-bg {
    .o-container {
        padding: 0 2.7rem !important;
        @media ${device.tablet} {
          padding: 0 2rem !important;
        }
    }
        .infographic-wrapper {
          padding: 30px;
          @media ${device.ipadLandscapemin} {
            margin: 0 auto;
            padding: 0 2rem;
          }
          &:before {
            @media ${device.laptopPros} {
              height: 8px;
            }
          }
          .o-container {
            @media ${device.laptopMedium} {
              padding: 0 !important;
            }
            .genetic-cause {
              @media ${device.ipadLandscapemin} {
                padding: 88px 0 !important;
              }
              .genetic-cause__right-text-secion {
                p {
                  @media ${device.laptopMedium} {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
  }
  .discussion-guide {
    h2 {
      margin-bottom: 0;
    }
  }
  .disease-from-another {
    .listing-section {
        padding-left: 2rem !important;

      li {
        &:first-child {
          @media ${device.ipadLandscapemin} {
            padding: 25px 0 11px 25px;
          }
          @media ${device.desktopsignup} {
            padding: 11px 0 11px 25px;
          }
        }
        padding: 6px 0px 6px 25px;
      }
    }
  }
  .wrap {
    @media ${device.laptopMedium} {
      margin-top: 36px !important;
    }
    .video-section__inner-content {
      @media ${device.laptopMedium} {
        padding: 0px 42px 32px !important;
      }
      p {
        &:nth-child(1) {
          margin-bottom: 35px ;
          @media ${device.ipadLandscapemin} {
            margin-bottom: 0px;
          }
        }
      }
      .o-container {
        @media ${device.ipadLandscapemin} {
          padding: 0 2rem;
        }
        .video-section__inner-content {
          padding: 0 !important;
        }
        .video-section__inner-content__video-btn {
          box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 30%);

          .dtp-arrow-down {
            font-size: 9px;
            display: inline-block;
            transition: ease 0.3s;

            @media ${device.laptopMedium} {
              margin-left: 5px;
            }

            &:before {
              content: '';
              display: table;
              width: 23px;
              height: 11px;
              background-repeat: no-repeat;
              position: relative;
              left: 3px;
              top: 3px;
            }

            &.rotate {
              position: relative;
              transform: rotate(180deg) !important;
              top: 3px;
            }
          }
        }
      }

      &.first-video-section {
        padding-bottom: 88px;
      }
      .home-video-section {
        padding: 0;
        @media ${device.ipadLandscapemin} {
          padding: 0 2rem;
        }
        @media ${device.smalldeskstrt} {
          padding: 0 100px;
        }
        div.home-video {
          &.dna-video-container {
            padding: 20px 0px 16px !important;
            max-width: 100% !important;
            @media ${device.laptopPros} {
              margin-bottom: 0 !important;
            }
            @media ${device.tablet} and ${device.laptopMedium} {
              margin-bottom: 10px !important;
            }
            @media ${device.ipadLandscapemin} {
             padding-top: 38px !important;
             padding-bottom: 32px !important;
            }
          }
        }
        .video-section__inner-content {
          padding-bottom: 32px;
          @media ${device.ipadLandscapemin} {
            padding-bottom: 88px !important;
          }
          @media ${device.ipadLandscapemin} {
            padding-top: 36px !important;
          }
        }
      }
      .o-button {
          margin-left: 0 !important;
      }
      p {
        margin-bottom: 36px;
        @media ${device.tabletMax} {
          width: 100%;
        }
        @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
          margin-bottom: 0px;
        }
        @media ${device.smalldeskstrt} {
          margin-bottom: 45px;
        }
      }

    }
    .wrap-genes {
      @media ${device.laptopMedium} {
        padding: 0 2rem;
      }
      @media ${device.ipadLandscapemin} and ${device.desktopmenu} {
        max-width: 1208px;
        padding: 0 2rem;
        width: 100%;
      }
      .video-section__inner-content {
        .o-header {
          @media ${device.laptopMedium} {
            padding: 38px 0 20px;
            width: 100%;
          }
          p {
            @media ${device.laptopMedium} {
              width: 88% !important;
            }
          }
        }
      }
      div.faq-container {
        padding: 0 !important;
        @media ${device.ipadLandscapemin} {
          padding: 0 2rem;
        }
        .bg-color-component {
          padding: 35px 42px 32px;
          @media ${device.ipadLandscapemin} {
            padding: 0 2rem;
          }
        }
      }
    }
  }

  .o-container {
    padding: 0 2.7rem;
    @media ${device.tablet} {
      padding: 0;
    }
  }
  div {
    span {
      .o-container {
        padding: 0 2.7rem;
          @media ${device.tablet} {
            padding: 0 2rem;
          }
      }
    }
  }
  .youdohtml {
    .inherit-button-wrapper {
      @media ${device.laptopMedium} {
        padding-bottom: 40px !important;
      }
      div.media {
        &.media--document {
          @media ${device.laptopMedium} {
            width: 100% !important;
           }
           a {
            @media ${device.laptopMedium} {
              padding: 22px 20px !important;
            }
          }
        }
      }
    }
  }
  .acsbMenu {
    .inherited-para {
      padding-top: 80px;
      padding-bottom: 88px;

      @media ${device.laptopMedium} {
        padding-top: 27px;
        padding-bottom: 44px;
      }
      .o-container {
        @media ${device.tablet} {
          padding: 0 2rem;
        }
      }
      .o-inner {
        padding-left: 100px;
        padding-right: 100px;

        @media ${device.laptopMedium} {
          padding-left: 0px;
          padding-right: 0px;
        }

        .o-internal--h2 {
          font-size: 4.2rem;
          font-weight: 700;
          line-height: 5rem;
          color: #000000;
          text-align: center;

          @media ${device.laptopMedium} {
            max-width: 238px;
            margin: auto;
            font-size: 2.5rem;
            line-height: 3rem;
          }
        }
      }
      p {
        padding-top: 48px;
        max-width: 768px;
        margin: auto;
        text-align: center;
        color: #000000;
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 3.2rem;

        @media ${device.laptopMedium} {
          line-height: 2.2rem;
          padding: 15px 0 0;
          font-size: 1.8rem;
          font-weight: 500;
        }
      }
    }
  }
  .o-container {
    max-width: 1208px;
    margin-left: auto;
    margin-right: auto;
  }
  .inheriteed-retinal-card  {
    @media ${device.laptopPros}  {
      padding: 40px 27px 0px !important;
      width: auto;
    }
    @media ${device.tablet} {
      padding: 40px 0 120px !important;
    }
    @media ${device.ipadLandscapemin} {
      padding: 120px 0px !important;
    }
  }

  .accordion-section {
    .panel-group {
      div.panel-title {
        @media ${device.tablet} {
          padding-left: 26px !important;
        }
        @media ${device.ipadLandscapemin} {
          padding: 30px 32px 30px 20px !important;
        }
        h4.o-text-title {
          @media ${device.laptopMedium} {
            padding: 12px 11px 12px 0px !important;
            margin-bottom: 0;
          }
          .accordion-title-button {
            background: transparent;
            color: rgb(255, 255, 255);
            border: transparent;
            margin-top: -10px;
            text-align: left;
            width: 100%;
            font-weight: 700;
            line-height: 26px;
            padding: 0 8px;
            @media ${device.ipadLandscapemin} {
              line-height: 34px;
              padding: 0;
            }
            @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
              margin-right: 0;
            }
            &:after {
              height: 40px;
              width: 40px;
              position: absolute;
              right: 13.5px;
              top: 30%;
              background-size: 40px !important;
              box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
              border-radius: 50%;

              @media ${device.mobileMedium} {
                margin-right: 3px;
                top: 32%;
                margin-top: 3px;
              }
              @media ${device.tablet} and  ${device.laptopMedium} {
                right: 16px;
                top: 32%;
              }
              @media ${device.ipadLandscapemin} {
                height: 35px;
                width: 35px;
                background-size: 35px !important;
              }

            }
          }
        }
        &.onExpanded {
          .accordion-title-button {
            color: rgb(128, 0, 190) !important;
            font-family: "Gotham-Bold", Arial, Helvetica, sans-serif;
          }
          .accordion-icon {
            &.gtm-accordion {
              @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
                margin-right: 16px;
              }
            }
          }
        }
      }
      .panel {
        .panel-heading {
          .accordion-icon {
            &.gtm-accordion {
              margin-left: 8px;
              @media ${device.mobileMedium} {
                margin-top: 2px;
              }
              @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
                margin-right: 0;
              }
              @media ${device.desktopsignup} {
                margin-right: 17px;
              }
            }
          }
        }
        .panel-collapse {
          .panel-body {
            ul.listing-section {
              @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
                padding: 0px 76px 14px !important;
              }
              li {
                &:last-child {
                  padding: 0px 0px 24px 24px;
                  @media ${device.ipadLandscapemin} {
                    padding: 0px 0px 10px 25px;
                  }
                }
              }
              .level-two-first {
                @media ${device.laptopMedium} {
                 margin-left: -40px;
                 margin-top: 10px;
                }
                @media ${device.ipadLandscapemin}{
                  padding-left: 0;
                }
              }
            }
            p {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    div.o-container {
      padding: 0 2.7rem;
        @media ${device.tablet} {
          padding: 0 2rem;
        }
    }
  }

  .padding-cards-fix {
    padding: 0 2.7rem;
    @media ${device.tablet} {
      padding: 0 2rem;
    }
    .footer-block {
      .footer-block--row {
        @media ${device.laptopMedium} {
          padding: 0 42px;
        }
        h3{
          &.o-common-title  {
            margin-bottom: 0;
            @media ${device.ipadLandscapemin} {
              margin-bottom: 0.5rem;
            }
            @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
             margin: 0 24px;
            }
            &:before {
              display: none !important;
            }
          }
        }
        p.o-common-para {
          @media ${device.ipadLandscapemin} {
            margin-bottom: 1rem;
          }
          @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
            margin: 0 24px;
           }
        }
        div.footer-inner-block {
          margin-top:0;
          @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
            padding: 32px 0px 120px;
          }
          @media ${device.laptopMedium} {
            width: 100%;
            margin-left: 0 !important;
          }
          &:nth-child(2) {
            @media ${device.laptopMedium} {
              margin-top: 15px;
            }
          }
          a {
            &:after {
              margin-top: 0 !important;
            }
          }
        }
      }
      .media--image {
        img {
          padding: 8px 0px;
          margin: auto;
          text-align: center;
          display: block;
          height: 100px;
          width: 33px;
          @media ${device.ipadLandscapemin} {
            width: auto;
          }
        }
      }
    }
  }
  // .inherited-retinal-diseases-page {
  //   .footer{
  //     padding: 0;
  //     @media ${device.laptop} {
  //       margin: -15rem auto 0 !important;
  //     }
  //   }
  // }

`;

export const YouAreNotAlone = styled.div`
  margin-bottom : 84px;

  @media ${device.laptopMedium} {
    margin-bottom: 10px;
  }

  .o-container {
    padding: 0 2.7rem;
    @media ${device.tablet} {
      padding: 0 2rem;
    }
    .info-box {
      display: flex;
      align-items: center;
      background-color: #300064;
      padding: 27px 102px;
      position: relative;
      margin: auto;

      @media ${device.laptopMedium} {
        padding: 24px 40px;
        display: block;
      }

      .media {
        img {
          position: absolute;
          left: 100px;
          top: 47px;

          @media ${device.laptopMedium} {
            position: unset;
            left: 0;
            right: 0;
            text-align: center;
            display: block;
            margin: auto;
            width: 45px;
          }
        }
      }

      .o-paragraph {
        text-align: left;
        color: #fff;
        padding: 0 0 0 101px;
        font-weight: 500;
        font-size: 2.2rem;
        line-height: 3.2rem;

        @media ${device.laptopMedium} {
          text-align: center;
          margin-top: 10px;
          padding: 0;
          display: block;
          line-height: 2.4rem;
          font-size: 1.8rem;
        }

        @media ${device.tabletMax} {
          display: inline-block;
        }

        span {
          color: #00FFD9;
          display: inline-block;
        }
      }
    }
  }
`;

export const GeneVariants = styled.div`
  margin-bottom: 80px;
  width: 1170px;
  margin: auto;
  box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);

  @media ${device.laptopMedium} {
    max-width: 718px;
    width: auto;
    padding: 0 2.7rem;
    box-shadow: none;
  }

  .faq-container {
    position: relative;
    background-color: #F7F7F7;
    max-width: 1170px;
    box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
    padding: 0 2rem;

    @media ${device.laptopMedium} {
      max-width: 718px;
      padding: 0 4.2rem;
    }

    &:before {
      content: "";
      background: linear-gradient(90deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
      position: absolute;
      top: 0;
      width: 100%;
      height: 16px;
      left: 0;
      right: 0;
      z-index: 1;
      @media ${device.laptopPros} {
        height: 8px;
      }
    }
    .bg-color-component {
      .faq-block {
        position: relative;

        .o-top-space {
          @media ${device.ipadLandscapemin} {
            padding-top: 88px;
          }

          h2 {
            padding-top: 16px;
            color: #000000;
            text-align: center;
            padding-bottom: 40px;
            font-size: 4.2rem;
            font-weight: 600;
            line-height: 5rem;

            @media ${device.laptopMedium} {
              margin-top: 0;
              padding: 0;
              font-size: 2.5rem;
              line-height: 3rem;
            }
          }
        }
        .o-inner {
          margin: auto;
          position: relative;
        }
        .media {
          float: left;

          @media ${device.laptopMedium} {
            float: unset;
          }

          img {
            width: 68px;

            @media ${device.laptopMedium} {
              width: 33px;
              margin: 0 auto;
              display: block;
              margin-top: 22px;
              margin-bottom: 12px;
            }
          }
        }
      }
      .faq-answer__faq-title, .faq-answer__faq-content {
        padding-left: 100px;
      }
      .faq-answer__faq-title {
        font-weight: 700;
        color: #8000BE;
        font-size: 2.7rem;
        line-height: 3.4rem;

        @media ${device.laptopMedium} {
          font-size: 2rem;
          line-height: 2.4rem;
          padding-left: 0;
          margin: 0 auto;
          display: table;
        }
      }
      .faq-answer__faq-content {
        font-weight: 500;
        padding-top: 32px;
        font-size: 2.2rem;
        line-height: 3.2rem;
        margin-bottom: 48px;

        @media ${device.laptopMedium} {
          font-size: 1.8rem;
          line-height: 2.2rem;
          padding-top: 12px;
          padding-left: 0;
          text-align: center;
          margin-bottom: 44px;
        }

        span {
          color: #8000BE;
          font-weight: 700;
        }
      }
    }
  }
  .video-section__inner-content {

    >div {
      @media ${device.laptopMedium} {
        width: 100%;
        padding: 0 !important;
      }
    }

    .video-section__inner-content__video-btn {
      @media ${device.laptopMedium} {
        width: max-content;
      }
      @media ${device.tabletMax} {
        margin-left: -8px;
        width: max-content;
      }

    }

    .video-section {
      border-bottom: none;
      background-color: #F7F7F7;

      @media ${device.laptopMedium} {
        padding-bottom: 30px;
      }
    }
    .o-header {
      max-width: 700px;
      padding-bottom: 48px;
      font-size: 4.2rem;
      text-align: center;
      font-weight: bold;
      margin: auto;
      padding-top: 38px;

      @media ${device.laptopMedium} {
        padding: 38px 42px 20px;
        font-size: 2.5rem;
        line-height: 3rem;
        width: 86%;
      }
    }

    p {
      font-size: 2.2rem;
      line-height: 3.2rem;
      text-align: center;
      max-width: 768px;
      margin: auto;

      @media ${device.laptopMedium} {
        font-size: 1.8rem;
        width: 88%;
        line-height: 1.3;
        display: block;
        margin-bottom: 20px;
      }
      @media ${device.tabletMax} {
        width: 68%;
      }

      @media ${device.smalldeskstrt} {
        margin-bottom: 45px;
      }
    }

    .o-button {
      &.video-section__inner-content__video-btn {
        background: #8000BE ;
        color: #fff;
        border: 1px solid #fff;

        &:hover {
          background: #fff;
          color: #8000BE;
          border-color: #fff;
          font-weight: 700;
        }

        i {
          &:before {
            height: 19px;
          }
        }
      }
    }
  }
`;

export const Card = styled.div`
  padding: 120px 0;
  padding-bottom: 48px;

  @media ${device.laptopMedium} {
    padding-top: 40px;
    margin: 0 auto;
  }
  .o-header {
    padding-bottom: 48px;

    p {
      font-size: 4.2rem;
      font-weight: 700;
      line-height: 5rem;
      text-align: center;

      @media ${device.laptopMedium} {
        font-size: 2.5rem;
        line-height: 3rem;
        width: 86%;
        margin: auto;
      }
    }
  }
  img {
    margin: 0 auto;
    display: table;

    @media ${device.laptopMedium} {
      width: 100%;
    }
  }
  .generic-slider-no-image {
    position: relative;
    padding-top: 0;
    padding: 0;
    overflow: hidden;
    max-width: 968px;
    @media ${device.laptop} and ${device.desktopmenu}  {
      width: 95.8%;
    }
    @media ${device.desktopsignup} {
      padding: 0 1rem;
    }
    .swiper-button-disabled {
      background-color: #7323ca;
      opacity: .3;
    }
    .swiper-pagination {
      bottom: unset;
      top: 100px;
      background-color: #00FFD9;
      height: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      text-align: center;
      transition: 0.3s opacity;
      z-index: 10;

      @media ${device.laptopMedium} {
        top: 50px;
      }

      .swiper-progress-bar-fill {
        position: absolute;
        height: 8px;
        z-index: -1;
        background-color: #7323CA;
      }

      .swipper-prev-selected {
        background-color: #7323CA !important;
      }

      .swiper-pagination-bullet-active {
        background-color: #7323CA !important;
      }

      .swiper-pagination-bullet {
        width: 68px;
        height: 68px;
        margin: 0px !important;
        opacity: 1;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 100%;
        background-color: #00FFD9;

        &:before {
          content: "";
          background-size: 57%;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          width: 100%;
          height: 100%;
        }
        &.swiper-pagination-bullet-active {
          background-color: #7323CA;
        }
      }
    }
    .swiper-pagination-bullets {
      left: 0;
      width: 100%;
      @media ${device.laptop}  {
        margin: 0 2rem;
        width: 96%;
      }
      span {
        @media ${device.laptopMedium} {
          width: 45px !important;
          height: 45px !important;
        }
      }
    }

    .swiper-wrapper {
      margin-top: 150px;
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      box-sizing: content-box;

      @media ${device.laptopMedium} {
        margin-top: 75px;
        height: auto;
      }

      .swiper-slide {
        margin: 20px auto;

        @media ${device.laptopMedium} {
          width: 100% !important;
          margin-top: 5px;
          margin-bottom: 5px;
        }
      }

      .item {
        max-width: 950px;
        margin: auto;

        .item__content {
          margin-top: 30px;
          display: flex;
          margin: auto;
          @media ${device.laptop} {
            width: 100%;
          }

          @media ${device.laptopMedium} {
            display: block;
            width: 100%;
          }

          .item__icon {
            min-width: 80px;
            margin-right: 34px;

            @media ${device.laptopMedium} {
              display: block;
              margin: 16px auto;
              width: 80px;
            }

            img {
              width: 100%;
              height: auto;
              margin: auto;
              display: block;
              vertical-align: middle;
              border-style: none;

              @media ${device.laptopMedium} {
                width: 50%;
              }
            }
          }

          p {
            color: #000000;
            font-size: 2.2rem;
            font-weight: 500;
            line-height: 3.2rem;
            padding-right: 50px;

            @media ${device.laptopMedium} {
              text-align: center;
              font-size: 1.8rem;
              line-height: 2.6rem;
              padding-right: 0;
            }
          }
        }
      }
    }
    .slider-arrow-container {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      position: relative;
      @media ${device.ipadLandscapemin} {
        margin-top: 48px;
      }

      .slider-btn {
        align-items: center;
        background-color: #7323ca;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        height: 52px;
        width: 75px;
        justify-content: center;
        margin: 0 16px;
        position: relative;
        margin: 0px 10px;
        @media ${device.ipadLandscapemin} {
          width: 137px;
          height: 54px;
          margin: 0px 16px;
        }
        &:hover {
          transition: all 0.5s ease-in 0s !important;
        }
      }
    }
  }
`;

export const Video2 = styled.div `
  .video-section {
    .o-container {
      padding: 0 2.7rem !important;
      @media ${device.tablet} {
        padding: 0 2rem !important;
      }
      .video-section {
        background-color: #FFF;
        border-bottom: none;

        @media ${device.laptopMedium} {
          padding: 44px 20px 0;
        }

        .video-section__inner-content {
          @media ${device.laptopMedium} {
            padding-bottom: 88px;
          }

          &.first-video-section {
          padding-bottom: 88px;
        }

          .o-header {
            color: #000000;
          }

          p {
            color: #000000;
          }

          .o-button {
            &.video-section__inner-content__video-btn {
              background: #8000BE ;
              color: #fff;
              border: 1px solid #fff;
              @media  ${device.laptopMedium} {
                margin-top: 15px;
              }
              @media  ${device.ipadLandscapemin} and ${device.laptopair2max} {
                margin-top: 10px;
              }

              i {
                vertical-align: baseline;
              }

              &:hover {
                background: #fff;
                color: #8000BE;
                border-color: #fff;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
`;
