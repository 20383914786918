export const patientIRD: any = {
    mainMenu: "Main Navigational Menu Consumer GCSO",
    footerMenu: "Footer Navigation Menu Consumer GCSO",
    banner: "Banner - HTML - IRD - DTC - Canada",
    carousel: "",
    footer: "Footer DTC HTML Canada",
    exitPopup: "Retina Canada Exit Popup HTML",
    hcpSwitcher: "HCP Switcher Modal Pop Up",
    topNav: "Top Navigation Menu Retina Canada HTML DTC",
    video1: "How do inherited retinal diseases happen -Inherited Retinal Disease",
    video2: "How are retinal diseases inherited - Inherited Retinal Disease",
    infographic: "Infographic Section - Inherited Retinal Disease",
    footerCallout: "Navigational Callout - Inherited Retinal Disease",
    siteLogo: "Site logos DTC",
    video1GTMLabel: "Genes 101",
    video2GTMLabel:  "Inheritance 101",
    ifYouDoSection: "If You Do - Inherited Retinal Disease ",
    hardToTell: "It's Hard Tell -  Inherited Retinal Disease",
    accordionSection: "Accordion Section - Inherited Retinal Disease New",
    irdDefines: "Inherited Retinal Disease Defines - Inherited Retinal Disease",
    familyHistory: "Family history alone - Inherited Retinal Disease",
    calloutSection: "Callout Section - Inherited Retinal Disease",
    whatAreGenes: "What Are Genes - Inherited Retinal Disease ",
    slider: "Slider - Inherited retinal disease",
    closerLook: "Let’s take a closer look - Inherited Retinal Disease",
    redirectLangUrl: {
      "redirectUrl": {
        "en": "/inherited-retinal-diseases",
        "fr": "/fr/inherited-retinal-diseases"
      }
    },
    backToTopGTM: "Back to top - Inherited Retinal Diseases",
    video1Attrs: {
      viewTranscriptGTMLabels: [
        {
          "data-gtm-event-label": "Genes 101 - View transcript",
          "className": "gtm-accordion",
          "aria-label": "View transcript for Genes 101"
        }
      ],
      hideTranscriptGTMLabels: [
        {
          "data-gtm-event-label": "Genes 101 - Hide transcript",
          "className": "gtm-accordion",
          "aria-label": "Hide transcript for Genes 101"
        }
      ]
    },
    video2Attrs: {
      viewTranscriptGTMLabels: [
        {
          "data-gtm-event-label": "Inheritance 101 - View transcript",
          "className": "gtm-accordion",
          "aria-label": "View transcript for Inheritance 101"
        }
      ],
      hideTranscriptGTMLabels: [
        {
          "data-gtm-event-label": "Inheritance 101 - Hide transcript",
          "className": "gtm-accordion",
          "aria-label": "Hide transcript for Inheritance 101"
        }
      ]
    },
    sliderAttrs: {
      bulletGTMLabels: [
        'inside the eye graphic - step 1',
        'inside the eye graphic - step 2',
        'inside the eye graphic - step 3'
      ],
      bulletAriaLabels: [
        'Slide 1',
        'Slide 2',
        'Slide 3'
      ],
      prevBtnGTMLabel: {
        'data-gtm-event-label': 'Inside the Eye graphic - next',
        'className': 'gtm-link-internal',
        'aria-label': 'Previous slide'
      },
      nextBtnGTMLabel: {
        'data-gtm-event-label': 'Inside the Eye graphic - previous',
        'className': 'gtm-link-internal',
        'aria-label': 'Next slide'
      }
    },
    footerClassName: "inherited-retinal-diseases-footer"
  }
